$(".m01 .slider").slick({
    arrows: true,
    autoplaySpeed: 5000,
    nextArrow: '#btnNext',
    prevArrow: '#btnPrevious',
    dots: false,
    autoplay: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    waitForAnimate: false,
    pauseOnHover: true,
    pauseOnFocus: true

});
var stopProgress = 0;
$('.m01 .slider').on( 'beforeChange', function( event, slick, currentSlide, nextSlide ) {
    if (nextSlide === 0 ) {
        stopProgress = 1;
        $('.m01 .slider').slick('slickPause');
        $(".inProgress0").removeClass('on');
        $(".inProgress1").removeClass('on');
        $(".inProgress2").removeClass('on');
        $(".inProgress3").removeClass('on');
        stopProgressbar();
    }
});

$('li.nav-item.dropdown').hover( function(){
    $('.m01 .slider').slick('slickPause');
    stopProgressbar();
}, function(){
    if (stopProgress === 1) {
        console.log('slickPause');
    } else {
        console.log('slickPlay');
        $('.m01 .slider').slick('slickPlay');
        startProgressbar();
    }
});


$('.m01 .slider').hover( function(){
    $('.m01 .slider').slick('slickPause');
    stopProgressbar();
}, function(){
    if (stopProgress === 1) {
        console.log('slickPause');
    } else {
        console.log('slickPlay');
        $('.m01 .slider').slick('slickPlay');
        startProgressbar();
    }
});


$('.pause').on('click', function() {
    stopProgress = 1;
    $('.m01 .slider').slick('slickPause');
    stopProgressbar();
});

// slider only on mobile with 2
$slick_slider = $('.search-news-slider');
settings_slider = {
    dots: true,
    arrows: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    // more settings
}
slick_on_mobile( $slick_slider, settings_slider);

// slick on mobile
function slick_on_mobile(slider, settings){
    $(window).on('load resize', function() {
        if ($(window).width() > 767) {
            if (slider.hasClass('slick-initialized')) {
                slider.slick('unslick');
            }
            return
        }
        if (!slider.hasClass('slick-initialized')) {
            return slider.slick(settings);
        }
    });
};

$(window).resize(function(e){
    if(window.innerWidth < 768) {
        if(!$('.search-news-slider').hasClass('slick-initialized')){
            mobileOnlySlider();
        }

    }else{
        if($('.search-news-slider').hasClass('slick-initialized')){
            $('.search-news-slider').slick('unslick');
        }
    }
});
//slider only on mobile with 2 end
//slider only on mobile with 1
// slider
$slick_slider2 = $('.slider-mobile');
settings_slider2 = {
    dots: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    // more settings
}
slick_on_mobile2( $slick_slider2, settings_slider2);

// slick on mobile
function slick_on_mobile2(slider, settings){
    $(window).on('load resize', function() {
        if ($(window).width() > 767) {
            if (slider.hasClass('slick-initialized')) {
                slider.slick('unslick');
            }
            return
        }
        if (!slider.hasClass('slick-initialized')) {
            return slider.slick(settings);
        }
    });
};

$(window).resize(function(e){
    if(window.innerWidth < 768) {
        if(!$('.slider-mobile').hasClass('slick-initialized')){
            mobileOnlySlider();
        }

    }else{
        if($('.slider-mobile').hasClass('slick-initialized')){
            $('.slider-mobile').slick('unslick');
        }
    }
});
//slider only on mobile wiht 1 end
//ticking machine
var percentTime;
var tick;
var time = 4;
var progressBarIndex = 0;

var progress = $('.inProgress');

$('.progressBarContainer .progressBar').each(function (index) {
    var progress = "<div class='inProgress inProgress" + index + "'></div>";
    $(this).html(progress);
});

function startProgressbar() {
    resetProgressbar();
    percentTime = 0;
    tick = setInterval(interval, 10);
}

function stopProgressbar() {
    resetProgressbar();
}

function interval() {
    if (($('.slider .slick-track div[data-slick-index="' + progressBarIndex + '"]').attr("aria-hidden")) === "true") {
        progressBarIndex = $('.m01 .slider .slick-track div[aria-hidden="false"]').data("slickIndex");

        console.log(progressBarIndex);

        if (progressBarIndex == 0) {
            $(".inProgress0").removeClass('on');
            $(".inProgress1").removeClass('on');
            $(".inProgress2").removeClass('on');
            $(".inProgress3").removeClass('on');
        }
        else if (progressBarIndex == 1) {
            $(".inProgress0").addClass('on');
            $(".inProgress1").removeClass('on');
            $(".inProgress2").removeClass('on');
            $(".inProgress3").removeClass('on');

        }
        else if (progressBarIndex == 2) {
            $(".inProgress0").addClass('on');
            $(".inProgress1").addClass('on');
            $(".inProgress2").removeClass('on');
            $(".inProgress3").removeClass('on');
        }
        else if (progressBarIndex == 3) {
            $(".inProgress0").addClass('on');
            $(".inProgress1").addClass('on');
            $(".inProgress2").addClass('on');
            $(".inProgress3").removeClass('on');

        }
        if (progressBarIndex > 3) {
            progressBarIndex = 0;
        }
        startProgressbar();

    } else {
        percentTime += 1 / (time + 2);
        $('.inProgress' + progressBarIndex).css({
            width: percentTime + "%"
        });
        if (percentTime >= 100) {
            $('.single-item').slick('slickNext');

            //console.log(progressBarIndex);

            if (progressBarIndex == 0) {
                $(".inProgress0").addClass('on');
            }
            else if (progressBarIndex == 1) {
                $(".inProgress0").addClass('on');
                $(".inProgress1").addClass('on');
            }
            else if (progressBarIndex == 2) {
                $(".inProgress0").addClass('on');
                $(".inProgress1").addClass('on');
                $(".inProgress2").addClass('on');
            }
            else if (progressBarIndex == 3) {
                $(".inProgress0").removeClass('on');
                $(".inProgress1").removeClass('on');
                $(".inProgress2").removeClass('on');
            }

            progressBarIndex++;

            if (progressBarIndex > 3) {
                progressBarIndex = 0;
            }
            startProgressbar();


        }
    }
}

function resetProgressbar() {
    $('.inProgress').css({
        width: 0 + '%'
    });
    clearInterval(tick);
}

startProgressbar();
// End ticking machine

$('.progressBarContainer div').click(function () {
    clearInterval(tick);
    var goToThisIndex = $(this).find("span").data("slickIndex");
    $('.m01 .single-item').slick('slickGoTo', goToThisIndex, false);
    console.log(goToThisIndex);
    startProgressbar();
});


$(".m2-slider").slick({
    infinite: false,
    arrows: true,
    nextArrow: '#btnNextm02',
    prevArrow: '#btnPreviousm02',
    dots: false,
    draggable: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 992,
        settings: {
            swipe: false,
            draggable: false,

            // centerMode: true,

        }

    }]
});

$(".m3-slider").slick({
    infinite: false,
    arrows: true,
    nextArrow: '#btnNextm03',
    prevArrow: '#btnPreviousm03',
    dots: false,
    autoplay: false,
    draggable: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 992,
        settings: {
            swipe: false,
            draggable: false,

            // centerMode: true,

        }

    }]
});

$(".m5-slider").slick({
    infinite: false,
    arrows: true,
    nextArrow: '#btnNextm05',
    prevArrow: '#btnPreviousm05',
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            // centerMode: true,

        }

    }]
});
$(".m7-slider").slick({
    infinite: false,
    arrows: true,
    dots: false,
    nextArrow: '#btnNextm07',
    prevArrow: '#btnPreviousm07',
    autoplay: false,
    slidesToShow: 2,
    slidesToScroll: 2,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            // centerMode: true,

        }

    }]
});
var top_events_slides_to_show = 3;
if (top_events_count) {
  if (top_events_count < 3) {
    top_events_slides_to_show = top_events_count;
  }
}
$(".slider-t7").slick({
    infinite: false,
    arrows: true,
    dots: true,
    nextArrow: '#btnNextt07',
    prevArrow: '#btnPrevioust07',
    autoplay: false,
    slidesToShow: top_events_slides_to_show,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            // centerMode: true,

        }

    }]
});

function slickify() {
    $('.m3-sliderr').slick({
        autoplay: true,
        autoplaySpeed: 4000,
        delay: 5000,
        speed: 700,
        nextArrow: '#btnNextm03',
        prevArrow: '#btnPreviousm03',
        responsive: [
            {
                breakpoint: 500,
                settings: "unslick"
            }
        ]
    });
}

slickify();
$(window).resize(function () {
    var $windowWidth = $(window).width();
    if ($windowWidth > 500) {
        slickify();
    }
});

$("#toTop").click(function () {
    $("html, body").animate({scrollTop: 0}, 1000);
});
$("#toTop1").click(function () {
    $("html, body").animate({scrollTop: 0}, 1000);
});
///////

$('.slider-for').slick({
    infinite: false,
    arrows: true,
    dots: false,
    autoplay: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    asNavFor: '.slider-nav',
    prevArrow: $('.prev'),
    nextArrow: $('.next'),
    responsive: [
        {
            breakpoint: 992,
            dots: true,
            infinite: false,
            arrows: false,
            dots: false,
        }
    ]
});
$('.slider-nav').slick({
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    focusOnSelect: true,
    responsive: [
        {
            slidesToShow: 1,
            slidesToScroll: 1,
            breakpoint: 992,
            dots: true
        }
    ]
});

$('a[data-slide]').click(function(e) {
    e.preventDefault();
    var slideno = $(this).data('slide');
    $('.slider-nav').slick('slickGoTo', slideno - 1);
});
////////
$(".p10-slider").slick({
    infinite: false,
    arrows: false,
    dots: false,
    autoplay: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 992,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            // centerMode: true,

        }

    }]
});