$(document).ready(function () {
    console.log("ready!");
});
jQuery(document).ready(function () {
    var offset = 220;
    var duration = 500;
    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > offset) {
            jQuery('.back-to-top').fadeIn(duration);
        } else {
            jQuery('.back-to-top').fadeOut(duration);
        }
    });

    jQuery('.back-to-top').click(function (event) {
        event.preventDefault();
        jQuery('html, body').animate({scrollTop: 0}, duration);
        return false;
    })
});

$(document).ready(function () {
    var maxHeight = -1;
    $('.same-height').each(function () {
        maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
    });
    $('.same-height').each(function () {
        $(this).height(maxHeight);
    });
});